// @ts-nocheck
import React, { useContext } from "react";
// context
import { LoginContext } from "../store/context/LoginProvider";
// actionTypes
import { USER_LOGOUT } from "../store/actions/actionTypes";
// utils
import { toastWarn } from "../utils/toast";
import { imageError } from "../utils/errors";
// comp
import MobileHeader from "./header/MobileHeader";
import WebsiteHeader from "./header/WebsiteHeader";
import DownloadLink from "../components/home/DownloadLink";
import PopupBox from "../components/shared_comp/PopupBox";
// Url
import { BaseURL } from "../api/apiUrl";
import { useNavigate } from "react-router-dom";

function Header({ marquee, socialLink, loading }) {
  const navigate = useNavigate();
  // context
  let [{ userData }, dispatch] = useContext(LoginContext);

  const handleLogout = () => {
    if (!window.confirm("Are you sure want to logout ?")) return;
    dispatch({ type: USER_LOGOUT });
    navigate("/");
  };

  const handleToggle = () => {
    if (!userData) return;
    if (isDisabled) {
      if (parseInt(userData?.game_balance) > 500)
        toastWarn("Turnover is existed, and game balance is above 500.");
      return;
    }
    document.getElementById("transfer-popup").classList.toggle("active");
  };

  const handleQrCodeToggle = () => {
    document.getElementById("qr-code-scan").classList.toggle("active");
  };

  let isDisabled =
    parseInt(userData?.turnover) > 0
      ? parseInt(userData?.game_balance) < 500
        ? false
        : true
      : false;
  let qrCode = socialLink?.qr ? `${BaseURL}/${socialLink?.qr}` : "http://";

  return (
    <>
      {/* Qr Code Scan  f*/}
      <PopupBox
        id="qr-code-scan"
        isTitleCenter={true}
        contentStyle={{ width: "400px" }}
        title="Scan QR Code"
        handleToggle={handleQrCodeToggle}
      >
        <div className="row pt-3 ps-2 d-flex align-items-center">
          <img
            src={qrCode}
            alt="..."
            style={{ width: "100%", height: "400px", objectFit: "fill" }}
            onError={imageError}
            className="d-block rounded-2"
          />
        </div>
      </PopupBox>
      <DownloadLink socialLink={socialLink} forMobile={true} />
      <div style={{ zIndex: 100 }} className="bg-transparent w-full col-12">
        <MobileHeader
          marquee={marquee}
          userData={userData}
          handleToggle={handleToggle}
          handleLogout={handleLogout}
          isDisabled={isDisabled}
        />
        <WebsiteHeader
          marquee={marquee}
          userData={userData}
          handleToggle={handleToggle}
          handleLogout={handleLogout}
          isDisabled={isDisabled}
          loading={loading}
        />
      </div>
      {/* Main to Game or Game to Main */}
    </>
  );
}

export default Header;
