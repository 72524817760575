export const ENGLISH = {
  home: "Home",
  mainBalance: "Main Balance",
  gameBalance: "Game Balance",
  profile: "Profile",
  friend: "Referral",
  betslip: "Bet Slip",
  history: "History",
  cashIn: "Cash in",
  cashOut: "Cash out",
  logout: "Logout",
  login: "Login",
  gameCode: "Game Code",
  turnOver: "Turnover",
  transferPlaceHolder: "Please enter amount you want to transfer.",
  pleaseTransfer: "Please Transfer",
  searchGame: "Search games...",
  twoD: "2D",
  threeD: "3D",
  oneD: "1D",
  winNoHistory: "Win Number",
  moreGame: "More Games",
  playNow: "Play Now",
  transfer: "Transfer",
  apply: "Apply",
  notice: "Notice",
  back: "Back",
  noresult: "No results found",
  category: {
    newGame: "New Games",
    football: "Sport Book",
    lottery: "Lottery",
    cardGame: "Card Games",
    liveCasino: "Live Casino",
    fishingGame: "Fishing Games",
    slotGame: "Slot Games",
    promotion: "Promotion",
    blog: "Blog",
    other: "Other Games",
  },
  profilePage: {
    level1: "Your account is level 1",
    level2: "Your account is level 2",
    upgradeLevel: "Upgrade Level",
    upgradeToLevel2: "Upgrade Level 2",
    level2Image:
      "Please upload a screenshot of Wave Pay, KBZPay, or AYA Pay Level 2",
    changePassword: "Change Password",
    oldPassword: "Old Password",
    newPassword: "New Password",
    userName: "User Name",
    email: "Email",
    phone: "Phone",
    dateOfBirth: "Date of Birth",
    referralCode: "Referral Code",
    yourReferralCode: "Your Refer Code",
    editProfile: "Edit Profile",
    confirm: "Confirm",
    send: "Send",
  },
  referralPage: {
    yourFriend: "Your Friends",
    gameRefAmount: "Game Refer Amount",
    claim: "Claim",
    name: "Name",
    date: "Date",
    section: "Section",
    amount: "Amount",
  },
  betslipPage: {
    twodSlips: "Two Digits Slips",
    threedSlips: "Three Digits Slips",
    cashinHistory: "Cashin History",
    cashoutHistory: "Cashout History",
    maintoGameBalanceHistory: "Main to Game Balance History",
    gametoMainBalanceHistory: "Game to Main Balance History",
    gameHistory: "Game History",
    date: "Date",
    threeddate: "3D Open Date",
    section: "Section",
    totalAmount: "Total Amount",
    totalBetNumbers: "Total Bet Numbers",
    winAmount: "Win Amount",
    status: "Status",
    paymentName: "Payment Name",
    accountHolderName: "Account Holder Name",
    transationID: "TID or Voucher Code",
    oldBalance: "Old Balance",
    cashinAmount: "Cashin Amount",
    cashoutAmount: "Cashout Amount",
    newBalance: "New Balance",
    winloss: "Win/loss",
    commission: "Commission",
    profitloss: "Profit/Loss",
    mainBalance: "Main Balance",
    transferAmount: "Transfer Amount",
    gameBalance: "Game Balance",
    referenceID: "Reference ID",
    validTurnover: "Valid Turnover",
    userId: "User ID",
    gameType: "GameType",
    stake: "Stake",
    totalBet: "Total Bet",
    totalPayout: "Total Payout",
    winLose: "Win/Lose",
    Provider: "Provider",
    BetAmount: "Bet Amount",
    PayoutAmount: "Payout Amount",
    wagerID: "Wager",
    beforeBalance: "Before Balance",
    BALANCE: "Balance",
    Game: "Game",
  },
  cashInPage: {
    cashin: "Cash In",
    holdername: "holder name",
    transationID: "Transaction ID",
    lastsixdigits: "last 6 digits",
    accountNumber: "account number",
    amount: "Amount",
    transferToAmount: "Please transfer to the following account.",
    request: "Request",
    promoCode: "Promo Code",
    voucherCode: "Voucher Code",
  },
  cashOutPage: {
    cashout: "Cash Out",
    holdername: "holder name",
    accountNumber: "account number",
    amount: "Amount",
    request: "Request",
  },
  loginPage: {
    signIn: "Login",
    phone: "Phone number",
    password: "Password",
    registerAcc: "Register an account",
    forgetPassword: "Forgot password?",
  },
  registerPage: {
    userName: "User name",
    phone: "Phone number",
    password: "Password",
    refferalCode: "Referral code",
    register: "Register",
    accountAlreadyExist: "Already have an account?",
    changePhone: "Change Phone",
    send: "Send",
  },
  forgetPassPage: {
    phone: "Phone number",
    send: "Send",
    password: "Password",
    confirmPassword: "Confirm Password",
    resetPassTitle: "Enter your registered mobile number to request OTP.",
  },
  sectionTwoD: {
    lottery: "Lottery",
    sportBook: "Sport Book",
    liveCasino: "Live Casino",
  },
  warning: {
    setAmount: "Please set amount first.",
    bettingClosed: "Betting has been closed.",
    lotteryClosed: "No digit data, lottery closed?",
    needLevel2: "Your account need to be level 2.",
    transferMainToGame: "Please at least transfer Main to Game once.",
    cannotClaim: "Cannot claim less than 0 Game Referr Amount.",
  },
  success: {
    cashInReq: "Cashin request sent!",
    cashOutReq: "Cashout request sent!",
    successChangePass: "Your password has been changed successfully!",
    verifyCodeSent: "Verification code sent!",
  },
  winHistoryPage: {
    luckyNo: "Lucky Number",
    section: "Section",
    date: "Date",
  },
  winnerListHistory: {
    title: "Winner List",
    section: "Section",
    lucky_number: "Lucky Number",
    no: "No.",
    phone: "Phone Number",
    bet_amount: "Bet Amount",
    win_amount: "Win Amount",
  },
};
