// @ts-nocheck
import React, { useContext, useState } from "react";
// lib
import i18n from "i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// shared-comp
import HeaderMarquee from "../../components/shared_comp/HeaderMarquee";
// images
import HeaderIcon from "../../asset/image/auland.png";
import MMFlag from "../../asset/image/localization/mm.png";
import UKFlag from "../../asset/image/localization/uk.png";
import ChinaFlag from "../../asset/image/localization/china.png";
import ThaiFlag from "../../asset/image/localization/tai.png";
import icon from "../../asset/image/favicon.png";
import { BaseURL } from "../../api/apiUrl";
// utils
import { moneyFormatter } from "../../utils/moneyFormatter";
import { removeLocalStorageNoJson } from "../../utils/localStorage";
// config
import { CN, CURRENT_LANG, EN, MM, THAI } from "../../localization/langConfig";
//icons
import {
  ArrowLeftOnRectangleIcon,
  BanknotesIcon,
  ClipboardDocumentListIcon,
  CreditCardIcon,
  UserCircleIcon,
  UserIcon,
} from "@heroicons/react/24/solid";
import LotteryIcon from "../../asset/image/lottery_icon.png";
// context
import { LoginContext } from "../../store/context/LoginProvider";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useEffect } from "react";
import axios from "axios";
import { getProfileApi } from "../../api/profileApi";
const FlagImage = ({ flagImg, width }) => {
  return (
    <img style={{ width, objectFit: "contain" }} src={flagImg} alt="..." />
  );
};

const WebsiteHeader = ({
  userData,
  handleToggle,
  handleLogout,
  isDisabled,
  marquee,
  loading,
}) => {
  let { t } = useTranslation();
  let { pathname } = useLocation();
  pathname = pathname.substring(1, pathname.length).split("/")[0];
  const navigate = useNavigate();
  const getLang = removeLocalStorageNoJson(CURRENT_LANG);
  const [language, setchangeLanguage] = useState(getLang ? getLang : MM);
  const [openLang, setOpenLang] = useState(false);
  const [openLottery, setOpenLottery] = useState(false);
  const [amount, setAmount] = useState(userData?.balance || 0);

  const [
    {
      gameManagement: { category },
    },
  ] = useContext(LoginContext);
  const changeLang = (lang) => {
    i18n.changeLanguage(lang);
    setchangeLanguage(lang);
    setOpenLang(false);
  };
  useEffect(() => {
    const handleVisibilityChange = async () => {
      if (document.visibilityState === "visible") {
        await getProfileApi({ token: userData?.token })
          .then((res) => {
            if (res?.status === 200) setAmount(res?.data?.balance);
          })
          .catch((err) => {
            throw err;
          });
      } else {
        // The tab is hidden
      }
    };

    setAmount(userData?.balance || 0);

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [userData?.balance, userData?.token]);
  return (
    <div
      className="position-fixed top-0 w-100"
      style={{ zIndex: 99999, backgroundColor: "#181818" }}
    >
      <div className="d-none position-relative d-lg-flex justify-content-md-between align-items-center">
        {/* Lang Switch */}
        <div
          className="d-flex justify-content-end pe-3"
          style={{ paddingLeft: "10px" }}
        >
          <div
            onClick={() => setOpenLang(!openLang)}
            className="d-flex cursor-pointer align-items-center px-2"
          >
            {language === EN ? (
              <FlagImage width={25} flagImg={UKFlag} />
            ) : language === MM ? (
              <FlagImage width={25} flagImg={MMFlag} />
            ) : language === CN ? (
              <FlagImage width={25} flagImg={ChinaFlag} />
            ) : (
              <FlagImage width={25} flagImg={ThaiFlag} />
            )}
            {/* Expand Icon */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="20px"
              fill="#fff"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
            </svg>
          </div>
          {/* Announcement Icon */}
          <img width={24} height={24} src={HeaderIcon} alt="..." />
        </div>
        {/* Land Switch: DropDown  */}
        <div
          className={`position-absolute ${
            openLang ? "d-flex" : "d-none"
          } flex-column gap-1 bg-white rounded-3 p-2`}
          style={{ left: "1rem", top: 40, zIndex: 9999 }}
        >
          <div
            className={`${
              language !== MM ? "d-flex" : "d-none"
            } gap-2 align-items-center cursor-pointer`}
            onClick={() => changeLang(MM)}
          >
            <FlagImage width={25} flagImg={MMFlag} />
            <span>ဗမာစာ</span>
          </div>
          <div
            className={`${
              language !== EN ? "d-flex" : "d-none"
            } gap-2 align-items-center cursor-pointer`}
            onClick={() => changeLang(EN)}
          >
            <FlagImage width={25} flagImg={UKFlag} />
            <span>English</span>
          </div>
          <div
            className={`${
              language !== THAI ? "d-flex" : "d-none"
            } gap-2 align-items-center cursor-pointer`}
            onClick={() => changeLang(THAI)}
          >
            <FlagImage width={25} flagImg={ThaiFlag} />
            <span>ไทย</span>
          </div>
          <div
            className={`${
              language !== CN ? "d-flex" : "d-none"
            } gap-2 align-items-center cursor-pointer`}
            onClick={() => changeLang(CN)}
          >
            <FlagImage width={25} flagImg={ChinaFlag} />
            <span>中国人</span>
          </div>
        </div>

        {/** marquee */}
        <div className="bs-flex d-flex align-items-center">
          <HeaderMarquee marquee={marquee} />
        </div>

        <div className="d-flex justify-content-end align-items-center mt-1">
          {userData?.token ? (
            <div className="d-flex ps-3 justify-content-between align-items-center gap-3">
              <div className="dropdown" style={{ cursor: "pointer" }}>
                <UserCircleIcon
                  width={35}
                  color="#f9db7b"
                  data-bs-toggle="dropdown"
                />
                <ul
                  style={{ zIndex: "10000" }}
                  className="dropdown-menu rounded-0 bg-black userdivtoggle"
                >
                  <Link
                    to="/profile"
                    className="profileBtn text-decoration-none d-flex align-items-center my-2"
                  >
                    <div className="">
                      <UserIcon width={25} color="#f9db7b" />
                    </div>
                    <li className="ps-3" style={{ color: "#FDDF7D" }}>
                      {t("profile")}
                    </li>
                  </Link>

                  <Link
                    to="/cashin"
                    className="profileBtn text-decoration-none d-flex align-items-center my-2"
                  >
                    <div className="">
                      <CreditCardIcon width={25} color="#f9db7b" />
                    </div>
                    <li
                      className="profileBtn ps-3 "
                      style={{ color: "#FDDF7D" }}
                    >
                      {t("cashIn")}
                    </li>
                  </Link>

                  <Link
                    to="/cashout"
                    className="profileBtn text-decoration-none d-flex align-items-center my-2"
                  >
                    <div className="">
                      <BanknotesIcon width={25} color="#f9db7b" />
                    </div>
                    <li
                      className="profileBtn ps-3 "
                      style={{ color: "#FDDF7D" }}
                    >
                      {t("cashOut")}
                    </li>
                  </Link>

                  <Link
                    to="/betslip"
                    className="profileBtn text-decoration-none d-flex align-items-center my-2"
                  >
                    <div className="">
                      <ClipboardDocumentListIcon width={25} color="#f9db7b" />
                    </div>
                    <li
                      className="profileBtn ps-3 "
                      style={{ color: "#FDDF7D" }}
                    >
                      {t("betslip")}
                    </li>
                  </Link>

                  <Link
                    to=""
                    onClick={handleLogout}
                    className="profileBtn text-decoration-none d-flex align-items-center my-2"
                  >
                    <div className="">
                      <ArrowLeftOnRectangleIcon width={25} color="#f9db7b" />
                    </div>
                    <li
                      className="profileBtn ps-3 "
                      style={{ color: "#FDDF7D" }}
                    >
                      {t("logout")}
                    </li>
                  </Link>
                </ul>
              </div>

              <div
                style={{ backgroundColor: "#f9db7b" }}
                className=" bg-opacity-75 rounded-2 p-1 text-center d-flex flex-wrap justify-content-center"
              >
                {/* Main Balance */}
                <svg
                  className="float-start"
                  fill="black"
                  xmlns="http://www.w3.org/2000/svg"
                  height={21}
                  width={21}
                >
                  <path d="M0 0h24v24H0z" fill="none" />
                  <path d="M11.8 10.9c-2.27-.59-3-1.2-3-2.15 0-1.09 1.01-1.85 2.7-1.85 1.78 0 2.44.85 2.5 2.1h2.21c-.07-1.72-1.12-3.3-3.21-3.81V3h-3v2.16c-1.94.42-3.5 1.68-3.5 3.61 0 2.31 1.91 3.46 4.7 4.13 2.5.6 3 1.48 3 2.41 0 .69-.49 1.79-2.7 1.79-2.06 0-2.87-.92-2.98-2.1h-2.2c.12 2.19 1.76 3.42 3.68 3.83V21h3v-2.15c1.95-.37 3.5-1.5 3.5-3.55 0-2.84-2.43-3.81-4.7-4.4z" />
                </svg>
                <span className="px-1 fw-bolder fs-6 font-monospace px-md-3 text-black">
                  {moneyFormatter(parseInt(amount))}
                </span>
              </div>
            </div>
          ) : (
            <div className="w-100 d-flex gap-3 ps-3">
              <Link
                to="/login"
                className="text-decoration-none d-flex align-items-center justify-content-end"
              >
                <button className="rounded-2 btn btn-sm border-2 btn-outline-warning px-3">
                  <span className="fw-bolder">{t("login")}</span>
                </button>
              </Link>
              {/** <Link
                to="/register"
                className="text-decoration-none d-flex align-items-center justify-content-end"
              >
                <button className="rounded-5 btn btn-outline-warning px-5">
                  <span className="fw-bolder">
                    {t("registerPage.register")}
                  </span>
                </button>
              </Link> */}
            </div>
          )}
        </div>
      </div>

      {/**  Category Bar  */}
      <div className="d-none d-md-block bg-black text-center overflow-auto">
        <div className="text-warning gap-3 d-flex justify-content-around align-items-center">
          <div>
            <Link
              to="/"
              className="d-flex text-decoration-none text-white align-items-center"
            >
              <img src={icon} style={{ width: "3.6rem" }} alt="icon" />
            </Link>
          </div>

          <div className="d-inline-flex gap-4">
            <div
              role="button"
              onClick={() => {
                setOpenLottery(!openLottery);
              }}
              className="d-flex align-items-center px-2 py-1 h-100 rounded-2 shadow bg-dark categoryHover cursor-pointer"
            >
              <LazyLoadImage
                style={{ width: "30px", objectFit: "contain" }}
                effect="blur"
                src={LotteryIcon}
              />
              <div className="fs-6 ms-2 fw-bolder" style={{ color: "#FDDF7D" }}>
                {t("sectionTwoD.lottery")}
              </div>
            </div>

            {category && !loading ? (
              category.length ? (
                category.map((cat, idx) => (
                  <div
                    key={idx}
                    onClick={() => {
                      navigate(`${cat.name}`);
                      setOpenLottery(false);
                    }}
                    className={`d-flex align-items-center categoryHover px-2 py-1 h-100 rounded-2 shadow ${
                      pathname.split("%20").join("") ===
                      cat.name.split(" ").join("")
                        ? "bg-secondary text-black"
                        : "bg-dark"
                    }  cursor-pointer`}
                  >
                    <LazyLoadImage
                      style={{ width: "30px", objectFit: "contain" }}
                      effect="blur"
                      src={`${BaseURL}/${cat.image}`}
                    />
                    <div
                      className="fs-6 ms-2 fw-bolder"
                      style={{ color: "#FDDF7D" }}
                    >
                      {cat.name.toUpperCase()}
                    </div>
                  </div>
                ))
              ) : (
                <div>no category</div>
              )
            ) : (
              Array.from({ length: 5 }).map((a, idx) => (
                <div
                  key={idx}
                  style={{ minWidth: "8.6rem" }}
                  className=" d-flex gap-2 align-items-center px-2 py-1 rounded-2 shadow categoryHover cursor-pointer"
                >
                  <div
                    style={{ width: "25px", height: "25px" }}
                    className="bg-dark pulse bg-opacity-50 rounded-circle"
                  ></div>
                  <div
                    style={{ width: "80%", height: "20px" }}
                    className="bg-dark pulse bg-opacity-50 rounded-3"
                  ></div>
                </div>
              ))
            )}
          </div>
        </div>
      </div>

      <div
        className={`bg-dark rounded-3 ${
          !openLottery && "d-none"
        } border border-2 border-warning h-auto position-absolute`}
        style={{
          width: "12%",
          left: "30%",
          transform: "translate(-40%,0%)",
        }}
      >
        <ul
          className="mt-1 fw-bolder font-monospace list-unstyled"
          style={{ fontSize: ".96rem" }}
        >
          <Link
            to="/one/lottery"
            onClick={() => setOpenLottery(false)}
            className="text-decoration-none "
          >
            <li className="profileBtn ps-3 py-1" style={{ color: "#FDDF7D" }}>
              {t("oneD")}
            </li>
          </Link>

          <Link
            to="/two/lottery"
            onClick={() => setOpenLottery(false)}
            className="text-decoration-none "
          >
            <li className="profileBtn ps-3 py-1" style={{ color: "#FDDF7D" }}>
              {t("twoD")}
            </li>
          </Link>

          <Link
            to="/cone/lottery"
            onClick={() => setOpenLottery(false)}
            className="text-decoration-none "
          >
            <li className="profileBtn ps-3 py-1" style={{ color: "#FDDF7D" }}>
              Crypto 1D
            </li>
          </Link>

          <Link
            to="/crypto/lottery"
            onClick={() => setOpenLottery(false)}
            className="text-decoration-none "
          >
            <li className="profileBtn ps-3 py-1" style={{ color: "#FDDF7D" }}>
              {t("crypto")} 2D
            </li>
          </Link>

          <Link
            to="/three/lottery"
            onClick={() => setOpenLottery(false)}
            className="text-decoration-none "
          >
            <li className="profileBtn ps-3 py-1" style={{ color: "#FDDF7D" }}>
              {t("threeD")}
            </li>
          </Link>
          <Link
            to="/win-number"
            onClick={() => setOpenLottery(false)}
            className="text-decoration-none "
          >
            <li className="profileBtn ps-3 py-1" style={{ color: "#FDDF7D" }}>
              {t("winNoHistory")}
            </li>
          </Link>
          <Link
            to="/winner-list"
            onClick={() => setOpenLottery(false)}
            className="text-decoration-none "
          >
            <li className="profileBtn ps-3 py-1" style={{ color: "#FDDF7D" }}>
              {t("winnerListHistory.title")}
            </li>
          </Link>
        </ul>
      </div>
    </div>
  );
};

export default WebsiteHeader;
