// @ts-nocheck
import React from "react";

// swiper - css
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

function Footer({ socialLink }) {
  return (
    <div className="py-4 ps-2 rounded-end bg-dark bg-opacity-50">
      {/* Partner */}
      {/* Footer */}

      <div className="opacity-50 d-flex flex-column gap-3 ps-1">
        {/* FB  */}
        <a
          href={socialLink?.facebook}
          className="text-decoration-none pe-3 text-primary"
        >
          <i className="fa-brands fs-3 fa-facebook"></i>
        </a>

        {/* Messenger */}
        <a
          href={socialLink?.messenger}
          className="text-decoration-none pe-3 text-warning"
        >
          <i className="fa-brands fs-3 fa-facebook-messenger"></i>
        </a>

        {/* Viber */}

        <a
          href={socialLink?.viber}
          className="text-decoration-none pe-3 text-danger"
        >
          <i className="fa-brands fs-3 fa-viber"></i>
        </a>
        {/* Instagram */}

        <a
          href={socialLink?.instagram}
          className="text-decoration-none pe-3 text-info"
        >
          <i className="fa-brands fs-3 fa-telegram"></i>
        </a>
      </div>
    </div>
  );
}

export default Footer;
