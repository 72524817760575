import React from "react";

const HeaderMarquee = ({ marquee }) => {
  return (
    <marquee
      className="font-monospace fw-bolder"
      style={{ color: "#FDDF7D", fontSize: "16px" }}
    >
      {marquee}
    </marquee>
  );
};
export default HeaderMarquee;
